html {
    scroll-behavior: smooth;
}

.banner {
    background-color: black;
    background-image: url("../resources/banner.jpg");
    width: 100%;
    height: 40em;

    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
}

.banner-text{
    height: 50em;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.banner-text h1{
    font-size: 6em;
}

.banner-text legend{
    font-size: 2em;
    margin-bottom: 2em;
}

.indexLegend{
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2em;
    font-weight: bold;
}

.mapStyle{
    border: 0;
    border-radius: 2em;
}

/*Sections*/
.content-cards{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.card2{
    width: 30%;
    height: auto;
    text-align: center;
    box-shadow: 0 0 0.3em 0 rgba(0, 0, 0, 0.5);
    transform: scale(1);
    transition: transform 0.6s;
}

.card2:hover{
    box-shadow: 0 0 0.6em 0 rgba(0, 0, 0, 0.5);
    transform: scale(1.03);
    cursor: pointer;
}

.card2 i{
    margin: 1em 0 1em 0;
    color: #ffa500;
    font-size: 5em;
}
.card2 p{
    font-weight: 30em;
    font-size: 1.5em;
    margin-bottom: 1em;
    margin-left: 1em;
    margin-right: 1em;
    /*text-align: justify;*/
}


@media (max-width:1000px) {

    .banner {
        height: 25em;
        background-position: top;
    }

    .banner-text{
        height: 25em;
    }

    .banner-text h1{
        font-size: 3em;
    }

    .banner-text legend{
        font-size: 2em;
    }

    .card2{
        width: 90%;
    }

    .card2 p{
        font-weight: 25em;
        font-size: 1.5em;
    }

    article.card2{
        margin-bottom: 5em;
    }
}

.bgDeg {
    background: linear-gradient(0deg, #b993d0, #212529);
}